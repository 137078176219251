<template>
  <app-list-view
    server-side
    app="working_paper"
    model="workingpapertemplateheader"
    api-url="working-paper/working-paper-template/"
    :title="$t('menu.workingPaperTemplate')"
    :createTo="{ name: 'workingPaperTemplateCreate' }"
    :editTo="{ name: 'workingPaperTemplateEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'workingPaperTemplateList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.workingPaperTemplateName'),
          value: 'name'
        },
        {
          text: this.$t('fields.templateCategory'),
          value: 'template_category'
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'workingPaperTemplate'
    })
  }
}
</script>
